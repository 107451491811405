<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="780px">
                <v-card :style="($vuetify.breakpoint.mdAndDown) ? 'width:auto;' : 'min-width:320px;'">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto;">
                        <div style="min-height:10px;"></div>
                         <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">UUID:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <!--<span class="font-weight-bold"><v-chip label dark small>{{ item.uuid }}</v-chip></span>-->
                                    <span class="font-weight-bold">{{ (Object.prototype.hasOwnProperty.call(item, 'uuid')) ? item.uuid : "" }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Tag:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <!--<span class="font-weight-bold"><v-chip label dark small>{{ item.uuid }}</v-chip></span>-->
                                    <span class="font-weight-bold">{{ (Object.prototype.hasOwnProperty.call(item, 'tag')) ? item.tag : "" }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <!--<v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Owner:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ owner }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>
                                -->

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Created at:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small>{{ (Object.prototype.hasOwnProperty.call(item, 'created_at')) ? item.created_at : "N/A" }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <br><br><br>
                                
                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Device:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small color="#005A9C">{{ (Object.prototype.hasOwnProperty.call(item, 'cashier') && item.cashier !== null) ? item.cashier.name : "" }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Entrances since last maintenance:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold"><v-chip label dark small :color="entrancesColor(item)">{{ (Object.prototype.hasOwnProperty.call(item, 'entrances')) ? item.entrances : "" }}</v-chip></span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Servicemen:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ (Object.prototype.hasOwnProperty.call(item, 'servicemen')) ? item.servicemen : "" }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Maintenance timestamp:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ (Object.prototype.hasOwnProperty.call(item, 'maintenance_timestamp')) ? item.maintenance_timestamp : "" }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>

                                <v-flex xs12 sm12 md6 lg6>
                                    <v-icon left></v-icon><span class="detail-name-label" style="color:#000;">Maintenance notes:</span>
                                </v-flex>
                                <v-flex xs12 sm12 md6 lg6>
                                    <span class="font-weight-bold">{{ (Object.prototype.hasOwnProperty.call(item, 'note')) ? item.note : "" }}</span>
                                </v-flex>
                                <div style="min-height:30px;"></div>


                            </v-layout>
                        </v-container>
                    </v-card-text>
                    <v-divider></v-divider>
                    <!--<v-card-actions>
                        <v-btn color="error" text  @click="cancel()"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-actions>
                    -->
                    <v-card-text id="form--card--actions--mobile" v-if="$vuetify.breakpoint.xsOnly">
                        <v-btn block outlined style="" @click="cancel()" color="error"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-text>
                    <v-card-text id="form--card--actions--desktop" v-else>
                        <v-btn @click="cancel()" color="error" text><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>

</template>

<script>
import { formatDate, randomPinNumber, getCurrentDate, getHoursDiff, getMinutesDiff } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'

export default {
    name: 'telemetry-info-dialog',
    data: () => ({
        valid: false,
        item: {},
        dialog: false,
        loading: false,
        note: null,
        formTitle: "",
        cancelText: 'Close',
        event_timestamp: null,
        currentDate: null,
        cashier_info: {},
        

        
    }),

    watch : {

    },

    computed: {

        owner() {
            return "John Doe"
        },

        lastInvoice() {
            let invoice = null

            if(Object.prototype.hasOwnProperty.call(this.item, 'cashier_info') && this.item.cashier_info !== null) {
                invoice = this.item.cashier_info.last_invoice
            }

            return invoice
        }
    },

    methods: {
        setParametersData() {
            var vm = this
            vm.$store.commit('SET_PROGRESS', true)
            vm.$store.dispatch('PARAMETERS_DATA')
            .then(response => {
                //console.log(response)
                //console.log(response.data)
            })
            .catch(error => {
                console.error("### setParametersData ###");
                console.log(error)
            })
            .finally(() => {
                setTimeout(() => {
                    vm.$store.commit('SET_PROGRESS', false)  
                }, 700)
            })
        },

        setFormTitle() {
            this.formTitle = "";
            if(this.item.cashier !== undefined && this.item.cashier !== null) {
                this.formTitle = "Maintenance number #" + this.item.id + " [" + this.item.cashier.name + "]"
            } else {
                this.formTitle = "Maintenance number #" + this.item.id
            }
        },

        cancel() {
            this.dialog = false
            this.item = {}
        },

        

       entrancesColor(item) {
            if(Object.prototype.hasOwnProperty.call(item, 'entrances')) {
                if(item.entrances > process.env.VUE_APP_DEVICE_MAINTENANCE_COUNTER) {
                    return 'error'
                } 

                return 'success'
            }
       }
    },

    created() {
        var vm = this;
        this.currentDate = getCurrentDate()

        //window.console.log(this.item)

        this.setParametersData();

        EventBus.$on('VIEW_MAINTENANCE_ITEM_DIALOG', function(data) {
            //window.console.log("VIEW_MAINTENANCE_ITEM_DIALOG");
            //window.console.log(data)
            vm.item = data
            vm.cashier_info = data.cashier_info
            vm.setFormTitle();
            vm.dialog = true;

        });
    },

    mounted() {

    },

    beforeDestroy() {
        EventBus.$off('VIEW_MAINTENANCE_ITEM_DIALOG')
    },

    destroyed() {

    }

}

</script>

<style scoped>
    .v-card__title {
        background-color: #f5f5f5 !important;
    }

    span.detail-name-label {
        color: "#000" !important;
    }

    #form--card--actions--desktop {
        padding:10px 24px 10px !important;
    }

    #form--card--actions--mobile {
        padding:20px 24px 20px !important;
    }
</style>