<template>
    <div>
        <v-row justify="center">
            <v-dialog v-model="dialog" persistent="" scrollable  max-width="600px">
                <v-card :style="($vuetify.breakpoint.mdAndDown) ? 'width:auto;' : 'min-width:300px;max-width:600px;'">
                    <v-card-title>{{ formTitle }}</v-card-title>
                    <v-divider></v-divider>
                    <v-card-text style="height: auto;">
                        <br>
                        <form-validation-messages :messages="apiValidationMessages"></form-validation-messages>
                        <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                        <v-form id="form_add_maintenance" ref="form" v-model="valid" lazy-validation>
                            
                            <v-text-field
                                v-model="maintenance_timestamp"
                                label="Date"
                                append-icon="mdi-calendar-today"
                                readonly
                                filled
                            ></v-text-field>

                            <v-text-field
                                v-model="device_name"
                                label="Device"
                                append-icon="mdi-monitor-star"
                                readonly
                                filled
                            ></v-text-field>                            

                            <v-text-field
                                v-model="servicemen"
                                label="Servicemen"
                                append-icon="mdi-account-box"
                                readonly
                                filled
                            ></v-text-field>

                            <v-text-field
                                v-model="entrances"
                                label="Entrances"
                                append-icon="mdi-counter"
                                readonly
                                filled
                            ></v-text-field> 

                            <v-textarea
                            outlined
                            name="input-7-4"
                            label="Notes"
                            v-model="note"
                            counter
                            required
                            :rules="note_rules"
                            clearable
                            rows="10"
                            ></v-textarea>
                            
                        </v-form>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text id="form--card--actions--mobile" v-if="$vuetify.breakpoint.xsOnly">
                        <v-btn block outlined style="margin-bottom:15px;" @click="submitForm()" color="success" :disabled="disableSubmit" :loading="loading"><v-icon left>mdi-arrow-right</v-icon>{{ submitText }}</v-btn>
                        <v-btn block outlined style="" @click="cancel()" color="error" :disabled="loading"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-text>
                    <v-card-text id="form--card--actions--desktop" v-else>
                        <v-btn @click="submitForm()" color="success" text :disabled="disableSubmit" :loading="loading"><v-icon left>mdi-arrow-right</v-icon>{{ submitText }}</v-btn>
                        <v-btn @click="cancel()" color="error" text :disabled="loading"><v-icon left>mdi-close</v-icon>{{ cancelText }}</v-btn>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import { formatDate, getCurrentDate } from '@/helpers/utilities.js'
import { EventBus } from '@/main.js'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')

export default {
    name: 'edit-maintenance-dialog',

    components: {
        FormValidationMessages,
        FormSubmitMessages
    },

    data: () => ({
        valid: false,
        dialog: false,
        loading: false,
        item: {},
        disableSubmit: false,

        id: null,
        uuid: null,
        note: null,
        servicemen: null,
        device_name: null,
        entrances: null,
        maintenance_timestamp: null,

        cashier_uuid: null,
        cashier_nr: null,
        business_premise_id: null,

        formTitle: "Edit maintenance record",
        submitText: "Save",
        cancelText: 'Cancel',

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        note_rules: [v => !!v || 'Field notes is required'],

        currentDate: null,
    }),

    watch: {

    },

    computed: {
        columnBinding() {
            const binding = {}

            if (this.$vuetify.breakpoint.mdAndDown) binding.column = true

            return binding
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        },
    },

    methods: {
        submitForm() {
            let vm = this
            //this.item = {}

            //window.console.log("updating maitenance....")
            
            //this.demoSubmit()
            if(this.$refs.form.validate()) {
                vm.cancelText = "Cancel"
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                let payload = {
                    id: vm.id,
                    uuid: vm.uuid,
                    note: vm.note
                }

                //window.console.log("payload:")
                //window.console.log(payload)

                vm.$store.dispatch('UPDATE_MAINTENANCE', payload)  
                .then(response => {
                    //window.console.log("### EditMaintenanceDialog@submitForm")
                    //window.console.log(response.data)

                    vm.backendMessages.push('Maintenance record has been successfully saved in the system.');
                    vm.disableSubmit = true
                    vm.cancelText = "Close"
                })
                .catch(error => {
                    console.log('CREATE MAINTENANCE error')
                    console.log(error)

                    if(error.response.status === 422) {
                        window.console.log(error.response.data.errors)
                        for (const property in error.response.data.errors) {
                            //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                            var propertyData = error.response.data.errors[property];
                            //window.console.log(propertyData);
                            propertyData.forEach(message => {
                                vm.backendValidationMessages.push(message);
                            })
                        }
                    } else {
                        vm.backendErrorMessages.push('Ups... something went wront. Please try again.')
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        vm.loading = false
                    }, 600)
                })
            }

        },

        cancel() {
            this.dialog = false;
            this.clearForm()

            EventBus.$emit('refresh_dt_maintenance_devices', null);
            EventBus.$emit('refresh_dt_maintenances', null);
        },

        clearForm() {
            this.id = null
            this.uuid = null
            this.currentDate = null
            this.servicemen = null
            this.device_name = null
            this.entrances = null
            this.note = null
            this.loading = false
            this.disableSubmit = false
            this.cancelText = "Cancel"

            this.clearBackendMessages()
            this.$refs.form.resetValidation();
        },

        clearBackendMessages() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        setupData() {
            
        },

        demoSubmit() {
            let vm = this
            if(this.$refs.form.validate()) {
                vm.cancelText = "Cancel"
                vm.loading = true
                vm.backendValidationMessages = []
                vm.backendMessages = []
                vm.backendErrorMessages = []

                let randNumber = Math.floor(Math.random() * 2);

                setTimeout(() => {
                    vm.loading = false

                    if(randNumber == 0) {
                        vm.backendMessages.push('Maintenance record has been successfully entered into the system.');
                        vm.disableSubmit = true
                        vm.cancelText = "Close"

                    } else {
                        window.console.error("err")
                        vm.backendErrorMessages.push('Ups... Something went wrong :(')
                    }   

                    vm.loading = false

                }, 2500)
            }
        }


    },

    created() {
        var vm = this;
        EventBus.$on('EDIT_MAINTENANCE_DIALOG', function(data) {
            window.console.log(data)
            vm.item = data
            vm.maintenance_timestamp = vm.item.maintenance_timestamp
            //vm.servicemen = vm.$store.getters.user.name
            vm.servicemen = vm.item.servicemen
            vm.device_name = vm.item.cashier.name
            vm.entrances = vm.item.entrances
            vm.note = vm.item.note
            vm.dialog = true;
            vm.id = vm.item.id
            vm.uuid = vm.item.uuid

            vm.setupData()

            //window.console.log("Maintenance timestamp: ", vm.maintenance_timestamp);
        });
    },

    mounted() {

    },

    beforeDestroy() {
        this.item = {}
        EventBus.$off('EDIT_MAINTENANCE_DIALOG')
    },

    destroyed() {

    }
}

</script> 

<style scoped>
    .v-card__title {
        background-color: #f5f5f5 !important;
    }

    #form--card--actions--desktop {
        padding:10px 24px 10px !important;
    }

    #form--card--actions--mobile {
        padding:20px 24px 20px !important;
    }
</style>

